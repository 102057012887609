import { isDoctorPortal } from '../isDoctorPortal';
import { brandForDomain } from '../platform/brand';
import Constants from 'expo-constants';

export const getBrandAuthConfig = () => {
  const brand = brandForDomain();

  const portalConfig = Constants.expoConfig?.extra?.portal_auth0_web;
  const consumerConfig = Constants.expoConfig?.extra?.auth0_web;

  if (isDoctorPortal()) {
    return portalConfig ?? {};
  }

  if (brand === 'leafdoctors') {
    return consumerConfig ?? {};
  }

  return portalConfig ?? {};
};
