import { Platform } from 'react-native';
import * as Sentry from '@sentry/react';
import { useBrandName } from '../../hooks/useBrand';
import { isDoctorPortal } from '../isDoctorPortal';

export const initScripts = () => {
  if (Platform.OS !== 'web') {
    return;
  }

  const loadScripts = document?.location?.hostname?.indexOf('localhost') < 0;
  if (!loadScripts) {
    return;
  }

  const { gtm, cio } = useBrandName();

  if (!isDoctorPortal()) {
    (function (w, d, s, l, i) {
      w[l as any] = w[l as any] || [];
      (w[l as any] as any).push({
        'gtm.start': new Date().getTime(),
        event: 'gtm.js',
      });
      const f = d.getElementsByTagName(s)[0],
        j = d.createElement(s),
        dl = l != 'dataLayer' ? '&l=' + l : '';
      // @ts-ignore
      j.async = true;
      // @ts-ignore
      j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
      // @ts-ignore
      f.parentNode.insertBefore(j, f);
    })(window, document, 'script', 'dataLayer', gtm);

    (function () {
      let a, b, c;
      a = function (f: any) {
        return function () {
          // @ts-ignore
          _cio.push([f].concat(Array.prototype.slice.call(arguments, 0)));
        };
      };
      b = ['load', 'identify', 'sidentify', 'track', 'page'];
      for (c = 0; c < b.length; c++) {
        // @ts-ignore
        _cio[b[c]] = a(b[c]);
      }
      const t = document.createElement('script'),
        s = document.getElementsByTagName('script')[0];
      t.async = true;
      t.setAttribute('data-auto-track-page', 'false');
      t.id = 'cio-tracker';
      t.setAttribute('data-site-id', cio);
      t.src = 'https://assets.customer.io/assets/track.js';
      s.parentNode?.insertBefore(t, s);
    })();
  }

  Sentry.init({
    dsn: 'https://2514e569ac2a4061615343c36277e3fd@o4508188767879168.ingest.us.sentry.io/4508188818276352',
    enabled: true,
    enableTracing: false,
    tracesSampleRate: 0.1,
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
};
