import { webAuth } from '../../Common/utils/auth/getAuth';
import { useMemo, useRef, useState } from 'react';
import { useRouting } from '../../Common/hooks/useRouting';

export const useLogin = () => {
  const hasPasswordlessAuth = true;

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const formRef = useRef();

  const { searchParams } = useRouting();

  const formSchema = useMemo(() => {
    const fields = [
      {
        id: 'email',
        name: 'eMail Address',
        type: 'text',
        page: 1,
        value: '',
      },
    ];

    fields.push({
      id: 'password',
      name: 'Password',
      type: 'text',
      subType: 'password',
      value: '',
      page: 1,
    });

    return {
      fields,
      validation: JSON.stringify({
        type: 'object',
        properties: {
          password: { type: 'string', minLength: 2 },

          email: {
            type: 'string',
            format: 'email',
          },
        },
        required: ['email', 'password'],
        additionalProperties: false,
        errorMessage: {
          properties: {
            email: 'Please enter your eMail address',
            password: 'Please enter your password',
          },
        },
      }),
    };
  }, []);

  const onGoogleLogin = () => {
    const returnTo = searchParams.has('redirect') ? searchParams.get('redirect') : '/profile';

    webAuth.authorize({
      connection: 'google-oauth2',
      appState: { returnTo },
    });
  };

  const onAppleLogin = () => {
    const returnTo = searchParams.has('redirect') ? searchParams.get('redirect') : '/profile';

    webAuth.authorize({
      connection: 'apple',
      appState: { returnTo },
    });
  };

  const forgotPassword = () => {
    const enteredEmail = formRef.current.getState()?.values?.email;

    if (!enteredEmail || enteredEmail.indexOf('@') < 0) {
      alert('Enter your eMail address');

      return;
    }

    webAuth.changePassword(
      {
        email: enteredEmail,
        connection: 'Customers',
      },
      (results) => {
        alert("We've just sent you an email to reset your password.");
      },
    );
  };

  const onSubmit = (_, { email, password }) => {
    setLoading(true);
    setError(null);

    const returnTo = searchParams.has('redirect') ? searchParams.get('redirect') : '/profile';

    webAuth.login(
      {
        username: email,
        realm: 'Customers',
        password,
        appState: { returnTo },
      },
      (result) => {
        // @ts-ignore
        formRef.current.setupForm(true);
        setLoading(false);
        setError(`Please check your credentials & try again`);
      },
    );
  };

  return {
    hasPasswordlessAuth,

    forgotPassword,
    onAppleLogin,
    onGoogleLogin,
    onSubmit,
    formSchema,
    formRef,
    loading: loading,
    error,
  };
};
