import { useMemo } from 'react';
import { onError } from '@apollo/client/link/error';
import * as Sentry from '@sentry/react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { authenticationStateAtom } from '../state/authenticationState';
import { isDev } from './useIsDev';
import { ApolloClient, ApolloLink, HttpLink } from '@apollo/client';
import { isDoctorPortal } from '../utils/isDoctorPortal';
import { brandForDomain } from '../utils/platform/brand';
import { createAuthLink } from 'aws-appsync-auth-link';
import { cache } from '../utils/cache';
import { useIsMobile } from './useIsMobile';
import Constants from 'expo-constants';
import { devState } from '../../DevSwitcher/state/devState';

const apiURLForBrand = (localAPI: boolean) => {
  const brand = brandForDomain();

  if (localAPI) {
    return `http://localhost:${brand === 'portal' ? 4000 : 4001}?brand=${brand}`;
  }

  if (Constants.expoConfig?.extra?.deployment === 'autumn') {
    return Constants.expoConfig?.extra?.portal_api;
  }

  const hostname = () => {
    if (isDoctorPortal()) {
      return isDev() ? 'dev-api-portal' : 'api-portal';
    }

    return isDev() ? 'dev-api-patient' : 'api-patient';
  };

  if (brand === 'leafdoctors') return `https://${hostname()}.leafdoctors.com.au/graphql`;

  return `https://${hostname()}.leafdoctors.com.au/graphql`;
};

export let client;

export const useGraphQLClient = () => {
  const [authState, setAuthState] = useRecoilState(authenticationStateAtom);
  const { isAuthenticated, accessToken } = authState;

  // TODO REMOVE
  console.error(`XXXX auth initialise, isDev=${isDev()}, isAuthenticated=${isAuthenticated}, accessToken=${accessToken}`);

  const { isNative } = useIsMobile();

  const { localAPI } = useRecoilValue(devState);

  const apiURL = apiURLForBrand(localAPI);

  return useMemo(() => {
    const errorLink = onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }) => {
          console.log(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
          );

          Sentry.captureMessage(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
          );
        });
      }

      if (networkError && [401, 403].includes((networkError as any).statusCode)) {
        // Reset auth session here
        setAuthState({
          ...authState,
          isExpired: true,
          isAuthenticated: false,
          accessToken: undefined,
          user: undefined,
          isLoading: false,
        });
      }
    });

    const debugLogLink = new ApolloLink((operation, forward) => {
      return forward(operation).map((result) => {
        console.info('⚡️Response', operation.operationName, {
          variables: operation.variables,
          data: result.data,
        });
        return result;
      });
    });

    const wsAuth = isAuthenticated
      ? {
          type: 'OPENID_CONNECT',
          jwtToken: async () => {
            try {
              return `Bearer ${accessToken}`;
            } catch (e) {
              setAuthState({ ...authState, isExpired: true });

              return '';
            }
          },
        }
      : {
          type: 'API_KEY',
          apiKey: isDev() ? 'da2-muzt5opjsvc6dl5n3z742uwomu' : 'da2-2zqzcpzzo5hu7bpxm5nhue5m4u',
        };
    console.log("IsDev: ", isDev());
    console.log("IsAuthenticated: ", isAuthenticated)

    const httpLink = new HttpLink({
      uri: apiURL,
    });

    const authLink = createAuthLink({
      url: apiURL,
      region: 'ap-southeast-2',
      auth: wsAuth,
    });

    const link = ApolloLink.from(
      isDev() ? [errorLink, debugLogLink, authLink, httpLink] : [errorLink, authLink, httpLink],
    );

    client = new ApolloClient({
      cache: cache,
      connectToDevTools: isDev(),
      link,
      defaultOptions: {
        watchQuery: {
          fetchPolicy: 'cache-and-network',
          errorPolicy: 'all',
        },
        query: {
          fetchPolicy: 'cache-and-network',
          errorPolicy: 'all',
        },
        mutate: {
          errorPolicy: 'all',
        },
      },
    });

    (window as any).apolloClient = client;

    return client;
  }, [isAuthenticated, accessToken]);
};
